import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const deleteField = async ({ id }) => {
  const res = await axios.delete(`/app/bb_datasheep_projects_fields/${id}`);
  return res.data;
};

export const useDeleteFieldMutation = id => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: deleteField,
    onSuccess: async () => {
      await queryClient.invalidateQueries('PROJECT_FIELDS', id);
      await queryClient.invalidateQueries(['GET_PROJECT_FILE_DETAILS', id]);
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });
  return { mutate };
};
