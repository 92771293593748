import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NoFieldsInfo from 'modules/project-view/NoFieldsInfo';
import DraggableFieldsList from 'modules/project-view/DraggableFieldsList';
import { useGetProjectFieldsQuery } from 'pages/project-view-page/queries/useGetProjectFieldsQuery';
import { Loader } from 'components/common/Loader';

const ProjectFieldsSection = ({ setField, showManageFieldsModal }) => {
  const { id } = useParams();
  const { data: finaleFields, isLoading: isFieldsLoading, isError: isFieldsLoadingError } = useGetProjectFieldsQuery(id);

  if (isFieldsLoading) {
    return <Loader />;
  }

  const setClickedField = id => {
    setField(id);
    showManageFieldsModal();
  };

  if (isFieldsLoadingError) {
    return <h3>Error while loading fields</h3>;
  }

  return (
    <>
      {finaleFields.length === 0 ? (
        <NoFieldsInfo handleOpenModal={showManageFieldsModal} />
      ) : (
        <DraggableFieldsList showForm={showManageFieldsModal} setClickedField={setClickedField} finaleFields={finaleFields} />
      )}
    </>
  );
};
ProjectFieldsSection.propTypes = {
  setManageFieldsFormVisible: PropTypes.func,
  setField: PropTypes.func,
  showManageFieldsModal: PropTypes.func
};

export default ProjectFieldsSection;
