import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DeleteModal from 'components/common/DeleteModal';
import { useDeleteFilesMutation } from 'modules/project-view/mutations/useDeleteFilesMutation';
import { capitalize } from 'helpers/utils';
import ExportModal from 'components/common/ExportModal';

const options = [
  { value: 'delete', label: 'Delete' },
  { value: 'export', label: 'Export to file' }
];

const FilesBulkActions = ({ selectedRowIds, resetToggle }) => {
  const { id } = useParams();
  const [actionValue, setActionValue] = useState('');
  const [isDelModalVisible, setDelModalVisible] = useState(false);
  const [isExportModalVisible, setExportModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { mutate: deleteFiles } = useDeleteFilesMutation(id);

  const delHandler = () => {
    if (!selectedRowIds.length) {
      setDelModalVisible(false);
      return;
    }
    setIsDeleting(true);
    deleteFiles(selectedRowIds, {
      onSuccess: () => {
        if (resetToggle) {
          resetToggle();
        }
        setDelModalVisible(false);
      },
      onSettled: () => {
        setIsDeleting(false);
      }
    });
  };

  const handleAction = () => {
    if (actionValue === 'delete') {
      setDelModalVisible(true);
    } else if (actionValue === 'export') {
      setExportModalVisible(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Select
          value={options.find(el => el.value === actionValue)}
          options={options}
          onChange={option => setActionValue(option.value)}
          placeholder="Select action"
          isSearchable={false}
          className="w-100 w-sm-50"
        />
        <Button type="button" variant="falcon-default" size="sm" className="ms-2" onClick={handleAction} disabled={!actionValue}>
          {actionValue ? capitalize(actionValue) : 'Apply'}
        </Button>
      </div>
      <DeleteModal
        onShow={isDelModalVisible}
        onHide={() => setDelModalVisible(false)}
        id={selectedRowIds}
        onDelete={delHandler}
        isLoading={isDeleting}
      />
      <ExportModal show={isExportModalVisible} onHide={() => setExportModalVisible(false)} selectedRowIds={selectedRowIds} projectId={id} />
    </>
  );
};

FilesBulkActions.propTypes = {
  selectedRowIds: PropTypes.array,
  resetToggle: PropTypes.func,
  rowsData: PropTypes.array
};

export default FilesBulkActions;
