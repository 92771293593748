import { useQuery } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { useCallback, useMemo, useState } from 'react';

const getRules = async () => {
  const res = await axios.get(`/app/bb_datasheep_rules`);
  return res.data;
};

export const useGetRulesQuery = () => {
  const { data, isLoading, isError } = useQuery({
    queryFn: getRules,
    queryKey: ['ALL_RULES'],
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = useCallback(
    value => {
      setSearchValue(value);
    },
    [setSearchValue]
  );
  const filteredResults = useMemo(() => {
    const val = searchValue.trim().toLowerCase();
    if (!data) {
      return [];
    }
    if (!val.length) {
      return data;
    }
    return data.filter(el => el.name.toLowerCase().startsWith(val));
  }, [searchValue, data]);

  return {
    data: filteredResults,
    isLoading,
    isError,
    searchValue,
    setSearchValue: handleSearch
  };
};
