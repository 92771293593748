import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/new_project.png';

const Starter = ({ showModal }) => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <img src={editing} className="img-fluid" alt="new project image" />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
            <h3 className="text-primary">Start here...</h3>
            <p className="lead">Use the button below to add your first project...</p>
            <Button variant="falcon-primary" type="button" onClick={showModal}>
              Create a Project
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Starter.propTypes = {
  showModal: PropTypes.func.isRequired
};

export default Starter;
