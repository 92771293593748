import React from 'react';
import { Dropdown } from 'react-bootstrap';
import userSvg from 'assets/img/user.svg';
import Avatar from 'components/common/Avatar';
import { useLogoutMutation } from 'hooks/mutations/useLogoutMutation';

const ProfileDropdown = () => {
  const { mutate: handleLogout } = useLogoutMutation();

  const onLogOut = () => {
    handleLogout();
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" className="pe-0 ps-2 bg-transparent border-0 outline-none ">
        <Avatar src={userSvg} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={onLogOut}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
