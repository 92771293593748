import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Notiflix from 'notiflix';
import axios from 'axios';
import pagesRoutes from '../../routes/pages-routes';
import Flex from '../../components/common/Flex';

const DEFAULT_STATE_FORM = {
  email: ''
};

const ForgetPasswordPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: DEFAULT_STATE_FORM
  });

  const { mutate: resetPassword } = useMutation(
    async data => {
      setIsSubmitting(true);
      const res = await axios.post('/app/forgot-password', data);
      return res.data;
    },
    {
      onSuccess: res => {
        setIsSubmitting(false);
        if (res.success === 1) {
          Notiflix.Notify.success('An email has been sent successfully!');
        } else {
          Notiflix.Notify.failure(res.data.error || 'Failed to send email.');
        }
      },
      onError: error => {
        setIsSubmitting(false);
        const errorMessage = error?.response?.data?.error || error.message || 'Something went wrong.';
        Notiflix.Notify.failure(errorMessage);
      }
    }
  );

  const onSubmit = data => {
    resetPassword(data);
  };

  return (
    <div className="text-center">
      <h5 className="mb-0">Forgot your password?</h5>
      <small>Enter your email and we'll send you a reset link.</small>
      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Email address"
            name="email"
            type="text"
            {...register('email', { required: 'Email is required' })}
            isInvalid={!!errors.email}
          />
          {errors.email && <Form.Control.Feedback type="invalid">{errors.email.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Button className="w-100" type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Sending...
              </>
            ) : (
              'Send reset link'
            )}
          </Button>
        </Form.Group>
      </Form>
      <Flex justifyContent="center">
        <Link to={pagesRoutes.login}>
          Back to Login<span className="d-inline-block ms-1"> &rarr;</span>
        </Link>
      </Flex>
    </div>
  );
};

export default ForgetPasswordPage;
