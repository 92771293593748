import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

const textExtraction = async (data, setProgress) => {
  let intervalId;
  try {
    setProgress(10);

    intervalId = setInterval(() => {
      setProgress(prev => Math.min(prev + 10, 80));
    }, 1000);

    const res = await axios.post(`/app/textract`, data);

    clearInterval(intervalId);
    setProgress(100);
    return res.data;
  } catch (error) {
    clearInterval(intervalId);
    throw error;
  }
};

export const useTextExtractMutation = (id, { onMutate, setProgress, onError, onSuccess, onSettled } = {}) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: data => textExtraction(data, setProgress),
    onMutate: () => {
      onMutate && onMutate();
    },
    onError: error => {
      onError && onError(error);
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries(['GET_PROJECT_FILE_DETAILS', id]);
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
      onSuccess && onSuccess(data);
    },
    onSettled: () => {
      onSettled && onSettled();
    }
  });

  return { mutate };
};
