import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { AuthContext } from 'context/Context';
import { Loader } from 'components/common/Loader';
import CreditsText from 'modules/credits/CreditsText';

const CreditsPage = () => {
  const { auth, loading } = useContext(AuthContext);

  if (loading) {
    return <Loader />;
  }

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return (
    <Card className="bg-light">
      <h1 className="pt-3 pb-0 text-center fs-5 bg-light card-header">About Credits</h1>
      <Card.Body className="pt-5">
        <CreditsText />
      </Card.Body>
    </Card>
  );
};

export default CreditsPage;
