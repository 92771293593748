import { useMutation } from 'react-query';
import axios from 'axios';

const saveExtractedText = async ({ id, data }) => {
  const res = await axios.patch(`/app/bb_datasheep_projects_files/${id}`, data);
  return res.data;
};

export const useSaveExtractedTextMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: saveExtractedText
  });

  return { mutate, isLoading };
};
