import React, { useState, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useGetProjectFilesQuery } from 'modules/project-view/queries/useGetProjectFilesQuery';
import { useLockFileMutation } from 'hooks/mutations/useLockFileMutation';
import ReactTable from 'components/common/ReactTable/ReactTable';
import { Loader } from 'components/common/Loader';
import ReactTablePagination from 'components/common/ReactTable/ReactTablePagination';
import getUrlPath from 'helpers/getUrlPath';

const FilesSpecModal = ({ isVisible, closeModal, extractSelectedFiles }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage] = useState(5);
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { mutate: lockFile } = useLockFileMutation(id);
  const { data: filesData, isLoading, isError } = useGetProjectFilesQuery(id);

  const finaleFilesData = filesData?.data;
  const finaleFiles = Array.isArray(finaleFilesData) ? finaleFilesData.filter(file => file.protectedYN === '0') : [];

  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleShowConfirmModal = () => setShowConfirmModal(true);

  const toggleFileSelection = FileId => {
    setSelectedFiles(prevSelected => {
      if (prevSelected.includes(FileId)) {
        return prevSelected.filter(id => id !== FileId);
      } else {
        return [...prevSelected, FileId];
      }
    });
  };

  const toggleAllFiles = () => {
    if (selectedFiles.length === finaleFiles.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(finaleFiles.map(File => File._id));
    }
  };

  const columns = [
    {
      id: 'checkbox',
      header: () => <input type="checkbox" checked={selectedFiles.length === finaleFiles.length} onChange={toggleAllFiles} />,
      cell: ({ row }) => (
        <input
          type="checkbox"
          className="mx-2 cursor-pointer"
          checked={selectedFiles.includes(row.original._id)}
          onChange={() => toggleFileSelection(row.original._id)}
        />
      )
    },
    {
      accessorKey: 'file',
      header: 'File',
      id: 'file',
      enableSorting: false,
      cell: rowData => {
        const { imageISfile, _id } = rowData.row.original;
        const path = getUrlPath() + imageISfile;
        return (
          <span className="cursor-pointer" onClick={() => toggleFileSelection(_id)}>
            <img src={path} alt="File image" width={150} />
          </span>
        );
      }
    },
    {
      accessorKey: 'name',
      header: 'Name',
      id: 'name',
      enableColumnFilter: true,
      cell: rowData => {
        const { name, _id } = rowData.row.original;
        return (
          <div className="cursor-pointer d-flex text-start" onClick={() => toggleFileSelection(_id)}>
            {name}
          </div>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      id: 'status',
      cell: rowData => {
        const { status, _id } = rowData.row.original;
        return (
          <div className="cursor-pointer d-flex" onClick={() => toggleFileSelection(_id)}>
            {status}
          </div>
        );
      },
      enableColumnFilter: true
    }
  ];

  const handleSubmit = confirmed => {
    const unselectedFiles = finaleFiles.filter(file => !selectedFiles.includes(file._id) && file.protectedYN === '0');

    console.log('Selected files:', selectedFiles);
    console.log('Unselected files (before update):', unselectedFiles);

    if (confirmed) {
      console.log('User confirmed extraction.');

      unselectedFiles.forEach(file => {
        console.log(`Switching flag to "1" for file ID: ${file._id}`);
        lockFile(
          { id: file._id, data: { protectedYN: '1' } },
          {
            onSuccess: response => {
              console.log(`File ID: ${file._id} flag switched to "1". Response:`, response);
            },
            onError: error => {
              console.error(`Error switching flag for file ID: ${file._id}`, error);
            }
          }
        );
      });

      console.log('Sending selected files to extractText:', selectedFiles);
      extractSelectedFiles(
        { selectedFiles },
        {
          onSuccess: response => {
            console.log('extractText response:', response);
          },
          onError: error => {
            console.error('Error during extractText:', error);
          }
        }
      );

      unselectedFiles.forEach(file => {
        console.log(`Switching flag back to "0" for file ID: ${file._id}`);
        lockFile(
          { id: file._id, data: { protectedYN: '0' } },
          {
            onSuccess: response => {
              console.log(`File ID: ${file._id} flag switched back to "0". Response:`, response);
            },
            onError: error => {
              console.error(`Error switching flag back for file ID: ${file._id}`, error);
            }
          }
        );
      });

      handleCloseConfirmModal();
      setSelectedFiles([]);
      closeModal();
    } else {
      console.log('User cancelled extraction.');

      unselectedFiles.forEach(file => {
        console.log(`Switching flag back to "0" for file ID: ${file._id}`);
        lockFile(
          { id: file._id, data: { protectedYN: '0' } },
          {
            onSuccess: response => {
              console.log(`File ID: ${file._id} flag switched back to "0". Response:`, response);
            },
            onError: error => {
              console.error(`Error switching flag back for file ID: ${file._id}`, error);
            }
          }
        );
      });

      handleCloseConfirmModal();
    }
  };

  const paginatedFiles = useMemo(() => {
    const startIdx = (pageNumber - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    return finaleFiles.slice(startIdx, endIdx);
  }, [finaleFiles, pageNumber, rowsPerPage]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Error loading data</p>;
  }

  return (
    <>
      <Modal size="lg" show={isVisible} onHide={closeModal} aria-labelledby="manage-rules-modal">
        <Modal.Header>
          <Modal.Title id="manage-rules-modal">{'Choose Files to extract'}</Modal.Title>
          <FalconCloseButton onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          {finaleFiles.length === 0 ? (
            <p>No data available</p>
          ) : (
            <>
              <ReactTable
                data={paginatedFiles || []}
                columns={columns}
                loading={isLoading}
                tableClassName="rules-table"
                getTrProps={row => ({
                  onClick: () => toggleFileSelection(row.original._id),
                  style: {
                    cursor: 'pointer',
                    backgroundColor: selectedFiles.includes(row.original._id) ? '#f5f5f5' : 'white'
                  }
                })}
              />
              {finaleFiles.length > rowsPerPage && (
                <ReactTablePagination
                  setPageNumber={setPageNumber}
                  total={finaleFiles.length}
                  perPage={rowsPerPage}
                  pageNumber={pageNumber}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleShowConfirmModal} disabled={selectedFiles.length === 0}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Extract selected files?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to extract the selected files?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleSubmit(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSubmit(true)}>
            Extract
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FilesSpecModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  extractSelectedFiles: PropTypes.func.isRequired
};

export default FilesSpecModal;
