import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const createEmptyField = async ({ id, data }) => {
  const res = await axios.post(`/app/bb_datasheep_projects_fields?projectISbb_datasheep_projectsID=${id}`, data);
  return res.data;
};

export const useCreateFieldMutation = () => {
  const [newFieldId, setNewFieldId] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: createEmptyField,
    onSuccess: data => {
      setNewFieldId(data.new_id);
      queryClient.invalidateQueries('PROJECT_FIELDS');
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });
  return { mutate, isLoading, newFieldId };
};
