import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const createRule = async data => {
  const res = await axios.post(`/app/bb_datasheep_rules`, data);
  return res.data;
};

export const useCreateRuleMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: createRule,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['ALL_RULES']);
    }
  });

  return { mutate };
};
