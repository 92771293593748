import routes from './pages-routes';

const dashboardRoutes = [
  {
    name: 'Projects',
    to: routes.home,
    icon: 'layer-group',
    exact: true,
    active: true
  },
  {
    name: 'Rules',
    to: routes.rules,
    icon: 'wrench',
    exact: true,
    active: true
  }
];

export default dashboardRoutes;
