import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import pagesRoutes from 'routes/pages-routes';
import Notiflix from 'notiflix';

let baseURL;
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

switch (true) {
  case window.location.href.includes('localhost'):
  case window.location.href.includes('127.0.0.1'):
  case window.location.href.includes('dev.'):
    baseURL = 'https://api-dev.datasheep.com/api/v1';
    break;
  default:
    baseURL = 'https://ww1.datasheep.com/api/v1';
}

axios.defaults.baseURL = baseURL;
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    switch (error?.response?.status) {
      case 401:
        handleUnauthorizedError();
        break;
      case 500:
        handleServerError();
        break;
      default:
        () => {};
        break;
    }
    return Promise.reject(error);
  }
);

const handleUnauthorizedError = () => {
  const navigate = useNavigate();
  navigate(pagesRoutes.login, { replace: true });
  Notiflix.Notify.failure('Need to login again');
  window.location.reload();
};

const handleServerError = () => {
  const navigate = useNavigate();
  navigate(pagesRoutes.page500, { replace: true });
};

export const getSettings = async () => {
  try {
    const res = await axios.get(`/app/settings`);
    return res.data;
  } catch (error) {
    throw new Error('Failed to fetch settings');
  }
};

export const getSession = async () => {
  try {
    const res = await axios.get('/users/session');
    return res.data;
  } catch (error) {
    throw new Error('Failed to fetch session');
  }
};
