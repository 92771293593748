import { useQuery } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';

const getCurrentProject = async id => {
  const res = await axios.get(`/app/bb_datasheep_projects/${id}`);
  return res.data;
};

const REFETCH_TIME_IN_MILLISECONDS = 30000;
export const useGetProjectQuery = id => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['GET_PROJECT', id],
    queryFn: () => getCurrentProject(id),
    enabled: !!id,
    refetchInterval: REFETCH_TIME_IN_MILLISECONDS,
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { data, isLoading, isError };
};
