import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

const ModalReg = ({ show, onHide, title, bodyText, isLog, loginRoute }) => {
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        <CloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>{bodyText}</p>
          {isLog && (
            <Button>
              <Link to={loginRoute} className="text-white text-decoration-none">
                Login
              </Link>
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalReg.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  isLog: PropTypes.bool,
  loginRoute: PropTypes.string
};

ModalReg.defaultProps = {
  isLog: false,
  loginRoute: ''
};

export default ModalReg;
