import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const deleteProjectFiles = async arrIds => {
  const request = arrIds.map(id => axios.delete(`/app/bb_datasheep_projects_files/${id}`));
  await Promise.all(request);
};

export const useDeleteFilesMutation = projectId => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: deleteProjectFiles,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['GET_PROJECT_FILES', projectId]);
      Notiflix.Notify.success('Files were deleted successfully');
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate: mutateAsync };
};
