import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import './ReactTablePagination.scss';

const ReactTablePagination = ({ setPageNumber, total = 10, perPage = 5, pageNumber = 1 }) => {
  const pageCount = useMemo(() => Math.ceil(total / perPage), [total, perPage]);
  const handlePageClick = event => {
    setPageNumber(event.selected + 1);
  };

  return (
    <ReactPaginate
      containerClassName="react-table-pagination"
      pageClassName="page-item"
      activeClassName="active"
      breakLabel="..."
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount > 0 ? pageCount : 1}
      nextLabel="Next >"
      previousLabel=" < Prev"
      renderOnZeroPageCount={null}
      forcePage={pageNumber - 1}
      disabledClassName={'disabled'}
    />
  );
};

ReactTablePagination.propTypes = {
  setPageNumber: PropTypes.func,
  total: PropTypes.number,
  perPage: PropTypes.number,
  pageNumber: PropTypes.number
};

export default ReactTablePagination;
