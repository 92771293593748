import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import RulesTask from './RulesTask';
import IconButton from 'components/common/IconButton';
import ManageRulesModal from 'modules/rules/ManageRulesModal';

const RulesColumn = ({ column, tasks, handleFilter, filterText, handleRemoveRule }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const handleOpenModal = taskId => {
    setSelectedTaskId(taskId);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedTaskId(null);
  };

  return (
    <Col md={6} xs={12} className="rule-droppable-column">
      <div
        className="d-flex align-items-center justify-content-between position-sticky"
        style={{
          top: -2,
          backgroundColor: 'white',
          zIndex: 10,
          padding: '8px'
        }}
      >
        <h5 className="fs-0">{column.title}:</h5>
        {column.title === 'Available rules' && (
          <IconButton onClick={() => handleOpenModal(null)} variant="falcon-primary" size="sm" icon="plus" transform="shrink-3">
            Add Rule
          </IconButton>
        )}
      </div>

      <Droppable droppableId={column.id} key={column.id}>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ borderRadius: '2px', backgroundColor: 'lightgrey', padding: 8, minHeight: 500 }}
          >
            {column.id === 'all' && (
              <Form.Control placeholder="Filter rules" className="my-2" value={filterText} type="text" onChange={handleFilter} />
            )}
            {tasks
              .filter(el => el !== undefined)
              .map((task, index) => (
                <RulesTask
                  key={task.id}
                  task={task}
                  index={index}
                  handleRemoveRule={handleRemoveRule}
                  isCloseVisible={column.id === 'saved'}
                  openManageRulesModal={handleOpenModal}
                />
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <ManageRulesModal isVisible={isModalVisible} onClose={handleCloseModal} ruleId={selectedTaskId} />
    </Col>
  );
};

RulesColumn.propTypes = {
  column: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleRemoveRule: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired
};

export default RulesColumn;
