import React, { memo, useEffect, useRef, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import useDebounce from 'hooks/useDebounce';

const ReactTableSearch = ({ searchValue, setSearchValue, className }) => {
  const [value, setValue] = useState(searchValue ?? '');
  const [firstlyChecked, setFirstlyChecked] = useState(false);
  const inputRef = useRef(null);

  const searchVal = useDebounce(value);

  useEffect(() => {
    setSearchValue(value);
  }, [searchVal]);

  useEffect(() => {
    if (inputRef.current && firstlyChecked) {
      inputRef.current.focus();
    }
  }, [value, firstlyChecked]);

  return (
    <InputGroup className={classNames(className)}>
      <FormControl
        ref={inputRef}
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        size="sm"
        id="search-file-field"
        placeholder="Search..."
        type="search"
        className="shadow-none py-2"
        onFocus={() => setFirstlyChecked(true)}
      />
      <Button size="sm" variant="outline-secondary" className="border-300 hover-border-secondary">
        <FontAwesomeIcon icon="search" className="fs--1" />
      </Button>
    </InputGroup>
  );
};

ReactTableSearch.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default memo(ReactTableSearch);
