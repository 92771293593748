import React from 'react';
import { Circles } from 'react-loader-spinner';

export function Loader() {
  return (
    <Circles
      height="50"
      width="50"
      color="#84BFFA"
      ariaLabel="circles-loading"
      wrapperStyle={{ margin: '100px 0' }}
      wrapperClass="justify-content-center"
      visible={true}
    />
  );
}
