import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const updateRule = async ({ id, values: data }) => {
  const res = await axios.patch(`/app/bb_datasheep_rules/${id}`, data);
  return res.data;
};

export const useEditRuleMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: updateRule,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['ALL_RULES']);
    }
  });

  return { mutate };
};
