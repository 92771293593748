import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';

const assignTypeToField = async info => {
  const { id, data } = info;
  const res = await axios.patch(`/app/bb_datasheep_projects_fields/${id}`, data);
  return res.data;
};

export const useAssignTypeMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: assignTypeToField,
    onSuccess: () => {
      queryClient.invalidateQueries('PROJECT_FIELDS');
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { mutate };
};
