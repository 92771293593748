import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';

const editRuleToField = async info => {
  const { fieldId, rules, oldRulesIds, fieldRules, rulesToDelete } = info;

  const requestsToUpdate = rules.map((rule, index) => {
    const data = {
      fieldISbb_datasheep_projects_fieldsID: fieldId,
      ruleISbb_datasheep_rulesID: rule,
      orderNUM: index
    };
    if (oldRulesIds.includes(rule)) {
      const oldRuleId = fieldRules.find(el => el.ruleISbb_datasheep_rulesID === rule)._id;
      data._id = oldRuleId;
      return axios.patch(`/app/bb_datasheep_projects_fields_rules/${oldRuleId}`, data);
    } else {
      return axios.post(`/app/bb_datasheep_projects_fields_rules/`, data);
    }
  });

  const requestForDelete = rulesToDelete.map(ruleId => {
    return axios.delete(`/app/bb_datasheep_projects_fields_rules/${ruleId}`);
  });

  await Promise.all([...requestsToUpdate, ...requestForDelete]);
};

export const useEditRuleToFieldMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: editRuleToField,
    onMutate: () => Notiflix.Loading.hourglass({ svgColor: loadingSvgColor }),
    onSuccess: async () => {
      await queryClient.invalidateQueries('PROJECT_FIELDS');
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
