import { useMutation } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from 'context/Context';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';
import pagesRoutes from 'routes/pages-routes';
import { useNavigate } from 'react-router-dom';

const logout = async () => {
  const res = await axios.get(`/users/logout`);
  return res.data;
};

export const useLogoutMutation = () => {
  const { setAuth, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: logout,
    onMutate: () => Notiflix.Loading.hourglass({ svgColor: loadingSvgColor }),
    onSuccess: () => {
      setAuth(false);
        setUser({});
      navigate(pagesRoutes.logout);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });
  return { mutate };
};
