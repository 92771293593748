import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

const addRuleToField = async info => {
  const { id, rules } = info;
  const request = rules.map((rule, index) => {
    const data = {
      fieldISbb_datasheep_projects_fieldsID: id,
      ruleISbb_datasheep_rulesID: rule,
      orderNUM: index
    };
    return axios.post(`/app/bb_datasheep_projects_fields_rules`, data);
  });
  await Promise.all(request);
};

export const useAddRuleToFieldMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: addRuleToField,
    onSuccess: () => {
      queryClient.invalidateQueries('PROJECT_FIELDS');
    }
  });

  return { mutate };
};
