import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';
import axios from 'axios';

const updateField = async (id, data) => {
  const res = await axios.patch(`/app/bb_datasheep_projects_fields/${id}`, data);
  return res.data;
};

const updateAllFields = async items => {
  await Promise.all(items.map(el => updateField(el._id, el)));
};

export const useUpdateFieldsMutation = () => {
  let queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: updateAllFields,
    onMutate: () => {
      Notiflix.Loading.hourglass({ svgColor: loadingSvgColor });
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('PROJECT_FIELDS');
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
