import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';

const ReactTablePagination = ({ table }) => {
  if (!table) return null;

  const { pageIndex, pageSize } = table.getState().pagination;
  const pageCount = table.getPageCount();
  const globalFilter = table.getState().globalFilter;
  const totalRows = globalFilter ? table.getFilteredRowModel().rows.length : table.getPreFilteredRowModel().rows.length;

  const pages = [];

  for (let i = 0; i < pageCount; i++) {
    if (i === 0 || i === pageCount - 1 || (i >= pageIndex - 2 && i <= pageIndex + 2)) {
      pages.push(i);
    } else if (i === pageIndex - 3 || i === pageIndex + 3) {
      pages.push('...');
    }
  }

  return (
    <div className="mt-3">
      <div className="d-flex flex-column flex-md-row align-items-center gap-2 gap-md-6 mb-3">
        <div className="d-flex align-items-center gap-2 flex-wrap">
          <Button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className={`btn btn-link p-1 text-decoration-none ${
              !table.getCanPreviousPage() ? 'text-secondary' : 'text-primary'
            } border-0 bg-transparent`}
          >
            {'< Prev'}
          </Button>

          {pages.map((page, index) =>
            page === '...' ? (
              <span key={index} className="d-flex align-items-center">
                <span className="mx-2">...</span>
              </span>
            ) : (
              <Button
                key={index}
                onClick={() => table.setPageIndex(page)}
                className={`btn d-flex align-items-center justify-content-center p-2 ${
                  page === pageIndex ? 'bg-dark text-white' : 'bg-transparent border-0 text-dark'
                } rounded-circle`}
                style={{ width: '36px', height: '36px' }}
              >
                {page + 1}
              </Button>
            )
          )}

          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className={`btn btn-link p-1 text-decoration-none ${
              !table.getCanNextPage() ? 'text-secondary' : 'text-primary'
            } border-0 bg-transparent`}
          >
            {'Next >'}
          </Button>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center gap-2">
          <div className="d-flex align-items-center gap-2">
            <span>Total:</span>
            <strong className="mx-2">{totalRows}</strong>

            <Form.Select
              value={pageSize}
              onChange={e => table.setPageSize(e.target.value === 'All' ? totalRows : Number(e.target.value))}
              className="form-select w-auto"
            >
              <option value="All">All</option>
              {[10, 20, 30, 50].map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
    </div>
  );
};

ReactTablePagination.propTypes = {
  table: PropTypes.object.isRequired
};

export default ReactTablePagination;
