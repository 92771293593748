import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';

const uploadImagesOfProject = async ({ files, id, allow_duplicate_file_names, onUploadProgress }) => {
  let results = [];
  let totalBytesUploaded = 0;
  const totalBytes = files.reduce((sum, file) => sum + file.size, 0);

  const updateProgressSmoothly = progress => {
    requestAnimationFrame(() => {
      onUploadProgress(progress);
    });
  };

  for (const file of files) {
    const reader = new FileReader();

    await new Promise((resolve, reject) => {
      reader.onerror = () => reject('Error reading file.');

      reader.onload = () => {
        const stringFiles = { name: file.name, size: file.size, type: file.type, data: reader.result };
        const imgData = {
          projectISbb_datasheep_projectsID: id,
          name: stringFiles.name,
          imageISfile: stringFiles,
          allow_duplicate_file_names
        };

        axios
          .post('/app/bb_datasheep_projects_files', imgData, {
            onUploadProgress: progressEvent => {
              totalBytesUploaded += progressEvent.loaded;

              const totalProgress = Math.min(Math.round((totalBytesUploaded / totalBytes) * 100), 100);

              updateProgressSmoothly(totalProgress);
            }
          })
          .then(response => {
            resolve(response.data);
            results.push(response.data);
          })
          .catch(error => {
            reject(error.response?.data || error.message);
          });
      };

      reader.readAsDataURL(file);
    });
  }
  return results;
};

export const useUploadFilesMutation = id => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ files, id, allow_duplicate_file_names, onUploadProgress }) =>
      uploadImagesOfProject({ files, id, allow_duplicate_file_names, onUploadProgress }),
    onError: error => {
      console.error('Upload failed:', error.error);
    },
    onSuccess: async results => {
      const errors = results?.filter(result => result?.status === 'rejected' || result?.error);
      if (errors.length === 0) {
        console.log('Upload status:', 'All files uploaded successfully!');
      }
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  const uploadFiles = ({ files, allow_duplicate_file_names, onUploadProgress }, options = {}) => {
    mutate(
      { files, id, allow_duplicate_file_names, onUploadProgress },
      {
        ...options,
        onSuccess: (data, variables, context) => {
          if (options.onSuccess) {
            options.onSuccess(data, variables, context);
          }
        }
      }
    );
  };

  return { uploadFiles };
};
