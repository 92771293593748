import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import RulesBulkAction from 'modules/rules/RulesBulkAction';

import ReactTable from 'components/common/ReactTable/ReactTable';
import './RulesTable.scss';
import ManageRulesModal from 'modules/rules/ManageRulesModal';
import ReactTableSearch from 'components/common/ReactTable/ReactTableSearch';
import { useGetRulesQuery } from 'hooks/queries/useGetRulesQuery';

function RulesTable() {
  const [ruleId, setRuleId] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);

  const { data: rules, isLoading: isRulesLoading, isError: hasRulesError, searchValue, setSearchValue } = useGetRulesQuery();

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);
  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const resetRuleId = useCallback(() => {
    setRuleId(null);
  }, [setRuleId]);

  const toggleIdsSelection = id => {
    setDeletedIds(prev => {
      const currentIndex = prev.indexOf(id);
      const newCheckedItems = [...prev];
      currentIndex === -1 ? newCheckedItems.push(id) : newCheckedItems.splice(currentIndex, 1);
      return newCheckedItems;
    });
  };
  const toggleAll = () => {
    setDeletedIds(prev => {
      const ids = rules.map(file => file._id);
      const newCheckedItems = [...prev];
      prev.length > 0 ? (newCheckedItems.length = 0) : newCheckedItems.push(...ids);
      return newCheckedItems;
    });
  };

  const resetIds = () => {
    setDeletedIds([]);
  };

  const rowClickHandler = useCallback(
    id => {
      setRuleId(id);
      showModal();
    },
    [setRuleId, showModal]
  );

  const columns = [
    {
      id: 'checkbox',
      header: () => {
        return <input type="checkbox" checked={deletedIds.length === rules?.length} onChange={() => toggleAll()} />;
      },
      cell: ({ row }) => (
        <input
          type="checkbox"
          className="mx-2 cursor-pointer"
          checked={deletedIds.includes(row.original._id) || false}
          onChange={() => toggleIdsSelection(row.original._id)}
        />
      )
    },
    {
      accessorKey: 'name',
      header: 'Name',
      id: 'name',
      cell: rowData => {
        const { name, _id } = rowData.row.original;
        return (
          <button type="button" className="text-start outline-none border-0 p-0 bg-transparent" onClick={() => rowClickHandler(_id)}>
            {name}
          </button>
        );
      }
    },
    {
      accessorKey: 'type',
      header: 'Validation',
      id: 'type',
      cell: rowData => {
        const { type, _id } = rowData.row.original;
        return (
          <button className="outline-none border-0 p-0 bg-transparent" type="button" onClick={() => rowClickHandler(_id)}>
            {type}
          </button>
        );
      }
    }
  ];

  if (hasRulesError) {
    return <h2>Error occurs while loading rules</h2>;
  }

  return (
    <>
      <Row className="align-items-center justify-content-between flex-column flex-md-row mb-3 gap-2">
        <Col xs={12} sm={4}>
          <ReactTableSearch searchValue={searchValue} setSearchValue={setSearchValue} />
        </Col>
        <Col xs={12} sm={4}>
          <RulesBulkAction deletedIds={deletedIds} setModal={showModal} resetIds={resetIds} />
        </Col>
      </Row>
      <ReactTable isSticky columns={columns} data={rules} loading={isRulesLoading} tableClassName="rules-table" />
      <ManageRulesModal isVisible={isModalVisible} onClose={hideModal} ruleId={ruleId} resetRuleId={resetRuleId} />
    </>
  );
}

export default RulesTable;
