import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Notiflix from 'notiflix';
import { AuthContext } from 'context/Context';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Loader } from 'components/common/Loader';
import { useCreateFieldMutation } from 'modules/project-view/mutations/useCreateFieldMutation';
import { useAddRuleToFieldMutation } from 'modules/project-view/mutations/useAddRuleToFieldMutation';
import { useAssignTypeMutation } from 'modules/project-view/mutations/useAssignTypeMutation';
import { useDeleteFieldMutation } from 'modules/project-view/mutations/useDeleteFieldMutation';
import { useUpdateFieldMutation } from 'modules/project-view/mutations/useUpdateFieldMutation';
import RulesSection from 'modules/project-view/ManageFieldsModal/RulesSection';
import { useGetFieldQuery } from 'modules/project-view/queries/useGetFieldQuery';
import { useEditRuleToFieldMutation } from 'modules/project-view/mutations/useEditRuleToFieldMutation';

const DEFAULT_FORM_VALUES = { name: '', type: 'text', rules: [] };

const ManageFieldsModal = ({ fieldId, closeModal, isFileView }) => {
  const { id } = useParams();
  const { appSettings, user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const finaleFields = queryClient.getQueryData(['PROJECT_FIELDS', id]);
  const fieldTypes = appSettings?.dropdowns?.field_types || [];
  const { data: field, isLoading } = useGetFieldQuery(fieldId);
  const [newId, setNewId] = useState(fieldId ?? null);
  const [defaultFieldRulesIds, setDefaultFieldRulesIds] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
    resetField,
    setValue
  } = useForm({ defaultValues: DEFAULT_FORM_VALUES });

  const nameWatch = watch('name');
  const typeWatch = watch('type');
  const rulesWatch = watch('rules');

  const { mutate: createField, isLoading: isFieldCreating } = useCreateFieldMutation();

  const { mutate: addRules } = useAddRuleToFieldMutation();
  const { mutate: editCreateRules } = useEditRuleToFieldMutation();
  const { mutate: assignType } = useAssignTypeMutation();
  const { mutate: deleteField } = useDeleteFieldMutation(id);
  const { mutate: updateFieldName } = useUpdateFieldMutation(id);

  const cleanUp = () => {
    reset(DEFAULT_FORM_VALUES);
  };

  const handleDeleteField = () => {
    let confirmAction = confirm('Are you sure?');
    if (confirmAction) {
      deleteField(
        { id: field._id },
        {
          onSuccess: () => {
            cleanUp();
            closeModal();
            Notiflix.Notify.success(`Successfully deleted`);
            queryClient.invalidateQueries('GET_PROJECT_FILES');
          }
        }
      );
    }
  };

  useEffect(() => {
    if (field) {
      const rules = field?.rules?.sort((a, b) => +a.orderNUM - +b.orderNUM).map(rule => rule.ruleISbb_datasheep_rulesID);
      const patchedField = { rules, name: field.name, type: field.type || 'text' };
      setDefaultFieldRulesIds(rules);
      reset(patchedField);
    } else {
      setValue('type', 'text');
    }
  }, [field, reset, setValue]);

  useEffect(() => {
    if (typeWatch !== 'text' && newId) {
      resetField('rules');
    }
  }, [typeWatch]);

  const validationTypes = useMemo(() => {
    return fieldTypes.map(type => ({ value: type, label: type }));
  }, [fieldTypes]);

  const handleCreateField = () => {
    const name = getValues('name');
    const type = getValues('type') || 'text';
    if (!name) {
      return Notiflix.Notify.failure('Name is required');
    }

    if (field) {
      const data = {
        projectISbb_datasheep_projectsID: !isFileView ? id : field.projectISbb_datasheep_projectsID,
        userISbb_usersID: user?.userID,
        name
      };
      return updateFieldName({ id: field._id, data });
    }

    const data = {
      name,
      type,
      projectISbb_datasheep_projectsID: id,
      userISbb_usersID: user?.userID,
      orderNUM: finaleFields?.length + 1
    };

    if (!name.trim().length) return;
    if (finaleFields?.length > 0 && finaleFields?.some(el => el.name === name)) {
      return Notiflix.Notify.warning(`${name} is already in fields`);
    }

    createField(
      { id, data },
      {
        onSuccess: ({ new_id }) => {
          setNewId(new_id);
          handleAssignType(new_id);
          handleAddRules(new_id);
          cleanUp();
          closeModal();
          Notiflix.Notify.success('Successfully created');
          queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
        },
        onError: error => {
          Notiflix.Notify.failure('Failed to create field');
          console.error('Error creating field:', error);
        }
      }
    );
  };

  const compareArrays = (array1 = [], array2 = []) => {
    const uniqueArray1 = array1.filter(obj1 => !array2.some(obj2 => obj1 === obj2));
    const uniqueArray2 = array2.filter(obj2 => !array1.some(obj1 => obj2 === obj1));
    return { uniqueInArray1: uniqueArray1, uniqueInArray2: uniqueArray2 };
  };

  const handleCompare = (arr1 = [], arr2 = []) => {
    const { uniqueInArray2 } = compareArrays(arr1, arr2);

    let rulesToDelete = [];
    if (uniqueInArray2.length > 0 && field?.rules) {
      rulesToDelete = field.rules.reduce((acc, el) => {
        return uniqueInArray2.includes(el.ruleISbb_datasheep_rulesID) ? [...acc, el._id] : acc;
      }, []);
    }

    editCreateRules(
      {
        fieldId: field._id,
        rules: rulesWatch ?? [],
        oldRulesIds: defaultFieldRulesIds ?? [],
        fieldRules: field?.rules ?? [],
        rulesToDelete
      },
      {
        onSuccess: () => {
          closeModal();
          Notiflix.Notify.success('Successfully saved');
        },
        onError: error => {
          Notiflix.Notify.failure('Failed to save changes');
          console.error('Error editing rules:', error);
        }
      }
    );
  };

  const handleAddRules = newFieldId => {
    if (!rulesWatch || rulesWatch.length === 0) return;

    addRules(
      { id: !isFileView ? newFieldId : field.projectISbb_datasheep_projectsID, rules: rulesWatch },
      {
        onSuccess: () => {
          Notiflix.Notify.success('Rules successfully added');
        },
        onError: error => {
          Notiflix.Notify.failure('Failed to add rules');
          console.error('Error adding rules:', error);
        }
      }
    );
  };

  const onSubmit = () => {
    handleCreateField();
    if (field) {
      handleCompare(rulesWatch, defaultFieldRulesIds);
    } else {
      if (newId) {
        handleAddRules(newId);
        Notiflix.Notify.success('Successfully saved');
      }
    }
  };

  const handleCheckAndClose = () => {
    closeModal();
    cleanUp();
    setNewId(null);
    queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
  };

  const handleAssignType = fieldId => {
    const type = getValues('type') || 'text';

    if (!fieldId) {
      console.warn('Field ID is not created yet.');
      console.warn('Field ID:', fieldId);
      return;
    }

    const data = {
      projectISbb_datasheep_projectsID: !isFileView ? id : field?.projectISbb_datasheep_projectsID,
      type
    };

    assignType({ id: fieldId, data });
  };

  const handleMove = data => {
    setValue('rules', data);
  };

  return (
    <Modal size="xl" show={true} onHide={handleCheckAndClose} aria-labelledby="create-field-modal">
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{field ? field?.name : 'Create a Field'}</Modal.Title>
        <FalconCloseButton onClick={handleCheckAndClose} />
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-2">
              <Row>
                <Col md>
                  <Form.Label>Field Name</Form.Label>
                  <Form.Control
                    placeholder="Enter a name"
                    {...register('name', { required: 'Name is required' })}
                    type="text"
                    isInvalid={!!errors.name}
                  />

                  {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>}
                </Col>
                <Col md>
                  <Form.Label>Validation</Form.Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={validationTypes.find(c => c.value === field.value) || validationTypes[0]}
                        options={validationTypes}
                        className="react-select poppins-regular"
                        placeholder="Choose validation"
                        onChange={({ value }) => {
                          field.onChange(value);
                          handleAssignType(fieldId || newId);
                        }}
                        isDisabled={field ? !nameWatch : !newId}
                        key="validation"
                      />
                    )}
                  />
                </Col>
              </Row>
            </FormGroup>
            {isFieldCreating && <p className="text-600"> Creating a field id...</p>}
            <Row className="mb-3 mt-4">
              {typeWatch && typeWatch !== 'Choose validation' && (
                <RulesSection projectRulesIds={rulesWatch ?? []} typeWatch={typeWatch} handleMove={handleMove} />
              )}
              <Col xs={12}>
                {field ? (
                  <Form.Group className="d-flex gap-2 text-end mt-4">
                    <Button variant="outline-secondary" className="w-100" type="submit" disabled={!nameWatch || isFieldCreating}>
                      {isFieldCreating ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Saving...
                        </>
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button variant="falcon-danger" className="w-100" type="button" onClick={handleDeleteField} disabled={isFieldCreating}>
                      Delete
                    </Button>
                  </Form.Group>
                ) : (
                  <Button className="w-100 bg-dark mt-sm-2" type="submit" disabled={isFieldCreating}>
                    {isFieldCreating ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        {newId ? 'Saving...' : 'Creating...'}
                      </>
                    ) : newId ? (
                      'Save'
                    ) : (
                      'Create'
                    )}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

ManageFieldsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fieldId: PropTypes.string,
  isFileView: PropTypes.bool
};

export default ManageFieldsModal;
