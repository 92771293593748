import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

const changeBalance = async amount => {
  const response = await axios.post('/app/account', { amount });
  return response.data;
};

export const useChangeBalanceMutation = ({ onSuccess, onError }) => {
  const queryClient = useQueryClient();
  return useMutation(changeBalance, {
    onSuccess: async data => {
      await queryClient.invalidateQueries('balance');
      if (onSuccess) onSuccess(data);
    },
    onError: error => {
      if (onError) onError(error);
    }
  });
};
