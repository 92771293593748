import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import Main from 'Main';
import 'helpers/initFA';
import './Index.css';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <Main>
    <App />
  </Main>
);
