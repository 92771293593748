import { useMutation } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const logIn = async credentials => {
  const res = await axios.post(`/users/login`, credentials);
  return res.data;
};

export const useLoginMutation = () => {
  const { mutate } = useMutation({
    mutationFn: logIn,
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
