import { useEffect, useState } from 'react';
import { useExtractionInstructionsMutation } from 'modules/project-view/mutations/useExtractionInstructionsMutation';
import { Form, FormCheck } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ExtractionInstructions = ({ project, id, isProjectLoading }) => {
  const { mutate: updateInstruction, isLoading: isValueUpdating } = useExtractionInstructionsMutation();

  const [value, setValue] = useState(project?.post_extraction_instructionsISsmallplaintextbox ?? '');
  const [checked, setValue2] = useState(project?.do_not_auto_extract_fieldsYN ?? 0);

  useEffect(() => {
    if (project?.post_extraction_instructionsISsmallplaintextbox && !isProjectLoading) {
      setValue(project?.post_extraction_instructionsISsmallplaintextbox);
    }
  }, [project?.post_extraction_instructionsISsmallplaintextbox, setValue]);

  useEffect(() => {
    if (project?.do_not_auto_extract_fieldsYN && !isProjectLoading) {
      setValue2(project?.do_not_auto_extract_fieldsYN);
    }
  }, [project?.do_not_auto_extract_fieldsYN, setValue2]);

  const handleUpdate = () => {
    updateInstruction(
      { id, post_extraction_instructionsISsmallplaintextbox: value },
      {
        onError: () => setValue(project?.post_extraction_instructionsISsmallplaintextbox)
      }
    );
  };
  const handleCheckbox = (chk) => {
    updateInstruction(
      { id, do_not_auto_extract_fieldsYN: chk },
      {
        onError: () => setValue(project?.do_not_auto_extract_fieldsYN)
      }
    );
  };
  return (
    <div>
    <Form.Group>
      <FormCheck
          type="checkbox"
          value={1}
          id="do_not_auto_extract_fieldsYN"
          label={"Don't Auto-extract Fields"}
          style={{ marginRight: '11rem' }}
          name="do_not_auto_extract_fieldsYN"
          checked={checked==1?true:false}
          onChange={e => {setValue2(e.target.checked?1:0);handleCheckbox(e.target.checked?1:0)}}
        />
    </Form.Group>
    <Form.Group>
      <Form.Label>
        <p className="mb-0 mt-2">Post Extraction Instructions</p>
      </Form.Label>
      <Form.Control
        as="textarea"
        type="text"
        rows={3}
        style={{ resize: 'vertical' }}
        onBlur={handleUpdate}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      {isValueUpdating && <p>Updating...</p>}
    </Form.Group>
    </div>
  );
};

ExtractionInstructions.propTypes = {
  project: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isProjectLoading: PropTypes.bool.isRequired
};

export default ExtractionInstructions;
