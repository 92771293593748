import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';
import { loadingSvgColor } from 'helpers/utils';

const copyExistedProject = async id => {
  const res = await axios.post(`/app/copy_project/${id}`);
  return res.data;
};
export const useCopyProject = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: copyExistedProject,
    onMutate: () => Notiflix.Loading.hourglass({ svgColor: loadingSvgColor }),
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['ALL_PROJECTS']);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });
  return { mutate };
};
