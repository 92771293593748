import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/Context';
import propTypes from 'prop-types';

export default function PrivateRoute({ children, redirect }) {
  const { auth, loading } = useContext(AuthContext);
  const shouldRedirect = !auth && !loading;

  return shouldRedirect ? <Navigate to={redirect} /> : children;
}

PrivateRoute.propTypes = {
  children: propTypes.element.isRequired,
  redirect: propTypes.string.isRequired
};
