import { useQuery } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const getRule = async id => {
  const res = await axios.get(`/app/bb_datasheep_rules/${id}`);
  return res.data;
};
export const useGetRuleQuery = id => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['GET_RULE', id],
    queryFn: () => getRule(id),
    enabled: !!id,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { data, isLoading, isError };
};
