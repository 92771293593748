import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import { useLockProjectMutation } from 'pages/project-view-page/mutations/useLockProjectMutation';
import PropTypes from 'prop-types';

const ProjectHeader = ({ project, id }) => {
  const { mutate: updateProjectLock, isLoading: isProjectUpdating } = useLockProjectMutation();

  const onPadlockClick = () => {
    const data = { protectedYN: project.protectedYN === '1' ? '0' : '1' };
    updateProjectLock({ id, data });
  };

  return (
    <Card.Header>
      <div className="d-flex flex-column flex-md-row pt-4 justify-content-between align-items-center gap-2">
        <div className="d-flex gap-3 align-self-start">
          <button disabled={isProjectUpdating} className="p-0 cursor-pointer outline-none border-0 bg-transparent" onClick={onPadlockClick}>
            {isProjectUpdating ? (
              <FontAwesomeIcon className=" spinner" icon={faSpinner} />
            ) : (
              <FontAwesomeIcon icon={project.protectedYN === '1' ? faLock : faLockOpen} />
            )}
          </button>
          <h3 className="p-0 text-start fs-3 bg-light card-header">{project.name}</h3>
        </div>
        {project?.stats ? (
          <div>
            <div className="poppins-regular fs-0">
              <small>
                Project File storage <b>{project?.stats?.project_file_storage} GB</b>
              </small>
            </div>
            <div className="poppins-regular fs-0">
              <small>
              Text Extracted <b>{project?.stats?.percent_text_extracted}%</b>, &nbsp;
              <span>
                Files: <b>{project?.stats?.total_files}</b>, &nbsp;
              </span>
              <span>
                Extracted files: <b>{project?.stats?.text_extracted}</b>
              </span>
              </small>
            </div>
            <div className="poppins-regular fs-0">
              <small>
              Fields Extracted <b>{project?.stats?.percent_fields_extracted}%</b>, &nbsp;
              <span>
                Files: <b>{project?.stats?.total_files}</b>, &nbsp;
              </span>
              <span>
                Extracted files: <b>{project?.stats?.files_extracted}</b>
              </span>
              </small>
            </div>
          </div>
        ) : (
          <h4>No stats for the project</h4>
        )}
      </div>
    </Card.Header>
  );
};

ProjectHeader.propTypes = {
  project: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};

export default ProjectHeader;
