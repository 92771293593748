import React from 'react';
import logoutImg from 'assets/img/sheep.png';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pagesRoutes from 'routes/pages-routes';

const LogoutPage = () => (
  <div className="text-center">
    <img className="d-block mx-auto mb-4" src={logoutImg} alt="shield" width={100} />
    <h4>Thanks for using Data Sheep!</h4>
    <Button as={Link} color="primary" size="sm" className="mt-3" to={pagesRoutes.login}>
      <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="me-1" />
      Return to Login
    </Button>
  </div>
);

export default LogoutPage;
