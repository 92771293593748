import React from 'react';
import { Row, Container } from 'react-bootstrap';
import creditsTextData from './creditsTextData.json';
import { Loader } from 'components/common/Loader';
import { useGetPaymentLinksQuery } from 'hooks/queries/useGetPaymentLinksQuery';

const CreditsText = () => {
  const { isLoading: isLoadingLinks, error: linksError } = useGetPaymentLinksQuery();

  if (isLoadingLinks) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  if (linksError) {
    return (
      <ul>
        <li className="ms-4">{creditsTextData[2]}</li>
        <li className="ms-4">{creditsTextData[3]}</li>
        <li className="ms-4">{creditsTextData[4]}</li>
        <li className="ms-4">{creditsTextData[5]}</li>
      </ul>
    );
  }

  return (
    <Container>
      <Row className="align-items-start justify-content-between mb-3">
        <h4>{creditsTextData[0]}</h4>
        <p>{creditsTextData[1]}</p>
        <ul>
          <li className="ms-4">{creditsTextData[2]}</li>
          <li className="ms-4">{creditsTextData[3]}</li>
          <li className="ms-4">{creditsTextData[4]}</li>
          <li className="ms-4">{creditsTextData[5]}</li>
        </ul>
        <p>{creditsTextData[6]}</p>
        <h4>{creditsTextData[7]}</h4>
        <p>{creditsTextData[8]}</p>
        <h4>{creditsTextData[9]}</h4>
        <p>{creditsTextData[10]}</p>
        <ul>
          <li className="ms-4">{creditsTextData[11]}</li>
          <li className="ms-4">{creditsTextData[12]}</li>
          <li className="ms-4">{creditsTextData[13]}</li>
        </ul>
      </Row>
    </Container>
  );
};

export default CreditsText;
