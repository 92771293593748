import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const deleteProject = async id => {
  const res = await axios.delete(`/app/bb_datasheep_projects/${id}`);
  return res.data;
};

export const useDeleteProjectMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: deleteProject,
    onMutate: async id => {
      const previousProject = queryClient.getQueryData(['ALL_PROJECTS']);
      queryClient.setQueryData(['ALL_PROJECTS'], oldProjects => oldProjects?.filter(project => project._id !== id));
      return { previousProject };
    },
    onError: (error, id, context) => {
      queryClient.setQueryData(['ALL_PROJECTS'], context.previousProjects);
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['ALL_PROJECTS']);
    }
  });
  return { mutate };
};
