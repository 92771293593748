import React from 'react';
import { Card } from 'react-bootstrap';
import RulesTable from 'modules/rules/RulesTable';

const RulesPage = () => {
  return (
    <Card className="bg-light">
      <h3 className="pt-3 pb-0 text-start fs-3 bg-light card-header">Rules</h3>
      <Card.Body className="pt-3">
        <RulesTable />
      </Card.Body>
    </Card>
  );
};

export default RulesPage;
