import { useQuery } from 'react-query';
import axios from 'axios';

const fetchPaymentLinks = async () => {
  const { data } = await axios.get('/app/settings');
  return data.credit_purchase_links;
};

export const useGetPaymentLinksQuery = () => {
  return useQuery('paymentLinks', fetchPaymentLinks);
};
