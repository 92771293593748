import { useQuery } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';

const getProjectFile = async id => {
  const res = await axios.get(`/app/bb_datasheep_projects_files/${id}`);
  return res.data;
};

export const useGetProjectFileQueryDetails = id => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['GET_PROJECT_FILE_DETAILS', id],
    queryFn: () => getProjectFile(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { data, isLoading, isError };
};
