import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Loader } from 'components/common/Loader';
import ReactTable from 'components/common/ReactTable/ReactTable';
import { useParams } from 'react-router-dom';
import { useGetProjectFieldsQuery } from 'pages/project-view-page/queries/useGetProjectFieldsQuery';
import ReactTablePagination from 'components/common/ReactTable/ReactTablePagination';

const FilesSpecModal = ({ isVisible, closeModal, extractSelectedFields }) => {
  const [selectedFields, setSelectedFields] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage] = useState(8);

  const { id } = useParams();

  const { data: finaleFields, isLoading: isFieldsLoading, isError: isFieldsLoadingError } = useGetProjectFieldsQuery(id);

  const handleCloseModal = () => {
    closeModal();
  };

  const toggleFieldSelection = fieldId => {
    setSelectedFields(prevSelected => {
      if (prevSelected.includes(fieldId)) {
        return prevSelected.filter(id => id !== fieldId);
      } else {
        return [...prevSelected, fieldId];
      }
    });
  };

  const toggleAllFields = () => {
    if (selectedFields.length === finaleFields.length) {
      setSelectedFields([]);
    } else {
      setSelectedFields(finaleFields.map(field => field._id));
    }
  };

  const columns = [
    {
      id: 'checkbox',
      header: () => <input type="checkbox" checked={selectedFields.length === finaleFields?.length} onChange={toggleAllFields} />,
      cell: ({ row }) => (
        <input
          type="checkbox"
          className="mx-2 cursor-pointer"
          checked={selectedFields.includes(row.original._id)}
          onChange={() => toggleFieldSelection(row.original._id)}
        />
      )
    },
    {
      accessorKey: 'name',
      header: 'Name',
      id: 'name',
      cell: rowData => (
        <button type="button" className="text-start outline-none border-0 p-0 bg-transparent">
          {rowData.row.original.name}
        </button>
      )
    },
    {
      accessorKey: 'validation',
      header: 'Validation',
      id: 'validation',
      cell: rowData => (
        <button className="outline-none border-0 p-0 bg-transparent" type="button">
          {rowData.row.original.type}
        </button>
      )
    }
  ];

  const handleSubmit = () => {
    if (selectedFields.length > 0) {
      extractSelectedFields({ selectedFields });
    }
    handleCloseModal();
  };

  const paginatedFields = useMemo(() => {
    const startIdx = (pageNumber - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    return finaleFields.slice(startIdx, endIdx);
  }, [finaleFields, pageNumber, rowsPerPage]);

  if (isFieldsLoading) {
    return <Loader />;
  }

  if (isFieldsLoadingError) {
    return <p>Error loading fields</p>;
  }

  return (
    <Modal size="lg" show={isVisible} onHide={handleCloseModal} aria-labelledby="manage-rules-modal">
      <Modal.Header>
        <Modal.Title id="manage-rules-modal">Choose fields to extract</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        {finaleFields.length === 0 ? (
          <p>You have no created fields for this project yet</p>
        ) : (
          <>
            <ReactTable
              data={paginatedFields || []}
              columns={columns}
              loading={isFieldsLoading}
              tableClassName="rules-table"
              getTrProps={row => ({
                onClick: () => toggleFieldSelection(row.original._id),
                style: {
                  cursor: 'pointer',
                  backgroundColor: selectedFields.includes(row.original._id) ? '#f5f5f5' : 'white'
                }
              })}
            />
            {finaleFields.length > rowsPerPage && (
              <ReactTablePagination
                setPageNumber={setPageNumber}
                total={finaleFields.length}
                perPage={rowsPerPage}
                pageNumber={pageNumber}
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={selectedFields.length === 0}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FilesSpecModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  extractSelectedFields: PropTypes.func.isRequired
};

export default FilesSpecModal;
