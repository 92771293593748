import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';

const RulesTask = ({ task, index, handleRemoveRule, isCloseVisible, openManageRulesModal }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            userSelect: 'none',
            padding: 16,
            margin: '0 0 8px 0',
            minHeight: '50px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            borderRadius: '5px',
            position: 'relative',
            ...provided.draggableProps.style
          }}
        >
          <div className="d-flex">
            <b>{task.name}</b>
            {isCloseVisible && <FalconCloseButton size="sm" className="ms-auto" onClick={() => handleRemoveRule(task.id)} />}
          </div>
          <p>{task.content}</p>

          <div
            className="edit-button"
            style={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              fontSize: '14px',
              color: '#6c757d',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
            onClick={() => openManageRulesModal(task.id)}
          >
            <span>edit</span>
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{'>'}</span>
          </div>
        </div>
      )}
    </Draggable>
  );
};

RulesTask.propTypes = {
  task: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleRemoveRule: PropTypes.func.isRequired,
  isCloseVisible: PropTypes.bool,
  openManageRulesModal: PropTypes.func.isRequired
};

export default RulesTask;
