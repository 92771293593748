import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import ReactTablePagination from './ReactTablePagination';
import ReactTablePerPage from './ReactTablePerPage';
import './ReactTable.scss';
import { Loader } from 'components/common/Loader';

const ReactTable = ({
  data = [],
  columns,
  hasPagination = false,
  hasPerPage = false,
  pageNumber = 1,
  setPageNumber,
  perPage = 0,
  perPageOptions,
  setPerPage,
  total = 10,
  loading,
  onSortingChange,
  sorting,
  isSticky,
  maxHeight = '650px',
  tableClassName = ''
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange,
    state: { sorting }
  });

  if (loading) {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <Loader />
      </div>
    );
  }

  if (data?.length === 0) {
    return <h5>No data to display</h5>;
  }

  return (
    <div className="d-flex flex-column">
      <div className="position-relative" style={{ zIndex: 0 }}>
        <div className="table-wrapper" style={{ maxHeight }}>
          <table className={'react-table ' + tableClassName}>
            <thead className={`${isSticky ? 'sticky-top' : ''} bg-400 px-2`}>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      className="text-left align-middle py-3 px-2"
                      key={header.id}
                      {...(header.column.getCanSort() ? { onClick: header.column.getToggleSortingHandler() } : {})}
                    >
                      <div className="d-inline-flex gap-1">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/*todo return when sort API ready*/}
                        {/*<span className="content-center">*/}
                        {/*  {header.column.getIsSorted() === 'asc' ? (*/}
                        {/*    <FontAwesomeIcon*/}
                        {/*      className="mb-0"*/}
                        {/*      style={{ cursor: 'pointer' }}*/}
                        {/*      icon="sort-amount-up"*/}
                        {/*    />*/}
                        {/*  ) : header.column.getIsSorted() === 'desc' ? (*/}
                        {/*    <FontAwesomeIcon*/}
                        {/*      className="mb-0"*/}
                        {/*      style={{ cursor: 'pointer' }}*/}
                        {/*      icon="sort-amount-down"*/}
                        {/*    />*/}
                        {/*  ) : header.column.getCanSort() ? (*/}
                        {/*    <FontAwesomeIcon*/}
                        {/*      className="mb-0"*/}
                        {/*      style={{ cursor: 'pointer' }}*/}
                        {/*      icon="sort"*/}
                        {/*    />*/}
                        {/*  ) : null}*/}
                        {/*</span>*/}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td className={`py-1 px-2 text-start align-middle fs--1`} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map(footerGroup => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map(header => (
                    <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row py-2 mt-2">
        {hasPagination && (
          <ReactTablePagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber ?? (() => console.warn('pageNumber is not defined'))}
            total={total}
            perPage={perPage}
          />
        )}
        {hasPerPage && (
          <ReactTablePerPage
            total={total}
            perPage={perPage}
            perPageOptions={perPageOptions}
            setPerPage={setPerPage ?? (() => console.warn('perPage is not defined'))}
          />
        )}
      </div>
    </div>
  );
};

ReactTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  hasPagination: PropTypes.bool,
  hasPerPage: PropTypes.bool,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  perPage: PropTypes.number,
  perPageOptions: PropTypes.array,
  setPerPage: PropTypes.func,
  total: PropTypes.number,
  loading: PropTypes.bool,
  onSortingChange: PropTypes.func,
  sorting: PropTypes.array,
  isSticky: PropTypes.bool,
  maxHeight: PropTypes.string,
  tableClassName: PropTypes.string
};
export default ReactTable;
