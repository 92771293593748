import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import pagesRoutes from 'routes/pages-routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from './validationSchema';
import { useRegisterMutation } from 'pages/registration-page/mutations/useRegisterMutation';
import { AuthContext } from 'context/Context';
import Flex from 'components/common/Flex';
import ModalReg from 'components/common/ModalReg';

const DEFAULT_STATE_FORM = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  user_type: 1,
  isAccepted: false
};

const RegistrationPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: DEFAULT_STATE_FORM
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalLog, setModalLog] = useState(false);
  const [geoData, setGeoData] = useState(null);
  const { setUser } = useContext(AuthContext);

  const { mutate: handleRegister } = useRegisterMutation({
    onMutate: () => setIsSubmitting(true),
    onSuccess: data => {
      setIsSubmitting(false);
      setModalShow(true);

      if (data.success === 1) {
        setModalText(
          'Thanks! You have signed up. We have sent a verification email to you - please click on the verify link to enable your account'
        );
        setModalLog(true);
      } else {
        setModalText(data.error || 'Registration failed.');
        setModalLog(false);
      }
    },
    onError: error => {
      setIsSubmitting(false);
      setModalText(error.response?.data?.error || 'An unexpected error occurred. Please try again later.');
      setModalShow(true);
    }
  });

  useEffect(() => {
    if (window.geoip2) {
      window.geoip2.city(
        geo => {
          console.log('GeoIP Data:', geo);

          setGeoData({
            country: geo.country.iso_code,
            fullGeoData: geo
          });
        },
        error => {
          console.error('GeoIP Error:', error);
        }
      );
    } else {
      console.warn('GeoIP2 library is not available on window object.');
    }
  }, []);

  const onSubmit = data => {
    delete data.isAccepted;
    delete data.confirmPassword;

    setUser({
      first_name: data.first_name,
      last_name: data.last_name
    });

    const enhancedData = {
      ...data,
      ip_address: geoData?.country || null,
      geodata: geoData ? JSON.stringify(geoData.fullGeoData) : null
    };

    console.log('Data to be sent to server:', enhancedData);

    handleRegister(enhancedData);
  };

  return (
    <>
      <Flex className="justify-content-between flex-1 mb-2 mx-2">
        <Flex>
          <h5 id="modalLabel">Register</h5>
        </Flex>
        <Flex xs="auto">
          <p className="fs--1 text-600 mb-0">
            Have an account? <Link to={pagesRoutes.login}>Login</Link>
          </p>
        </Flex>
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Control placeholder="First name" {...register('first_name')} type="text" isInvalid={!!errors.first_name} />
          {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name.message}</Form.Control.Feedback>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control placeholder="Last name" {...register('last_name')} type="text" isInvalid={!!errors.last_name} />
          {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control placeholder="Email address" {...register('email')} type="text" isInvalid={!!errors.email} />
          {errors.email && <Form.Control.Feedback type="invalid">{errors.email.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control placeholder="Password" {...register('password')} type="password" isInvalid={!!errors.password} />
          {errors.password && <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Confirm Password"
            {...register('confirmPassword')}
            type="password"
            isInvalid={!!errors.confirmPassword}
          />
          {errors.confirmPassword && <Form.Control.Feedback type="invalid">{errors.confirmPassword.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
            <Form.Check.Input type="checkbox" {...register('isAccepted')} isInvalid={!!errors.isAccepted} />
            <Form.Check.Label className="form-label">
              I accept the{' '}
              <Link
                to="https://www.blueboxonline.com/termsuk"
                onClick={e => {
                  e.preventDefault();
                  window.open('https://www.blueboxonline.com/termsuk', 'termsWindow');
                }}
              >
                Terms & Conditions
              </Link>
            </Form.Check.Label>
          </Form.Check>
          {errors.isAccepted && <Form.Control.Feedback type="invalid">{errors.isAccepted.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-4">
          <Button className="w-100" type="submit" disabled={!isValid || isSubmitting}>
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Registering...
              </>
            ) : (
              'Register'
            )}
          </Button>
        </Form.Group>
      </Form>

      <ModalReg
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={modalLog ? 'Success' : 'Error'}
        bodyText={modalText}
        isLog={modalLog}
        loginRoute={pagesRoutes.login}
      />
    </>
  );
};

export default RegistrationPage;
