import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const ReactTablePerPage = ({ perPage, perPageOptions, setPerPage, total }) => {
  const handleSelect = option => {
    const { value } = option;
    setPerPage(Number(value));
  };

  return (
    <div className="d-flex gap-2 align-items-center justify-content-center">
      <span className="overflow-hidden text-truncate" style={{ minWidth: '70px', maxWidth: '120px' }}>
        Total: {total}
      </span>
      |
      <Select
        value={perPageOptions.find(el => el.value === perPage) ?? perPageOptions[0]}
        options={perPageOptions}
        onChange={handleSelect}
        isSearchable={false}
        menuPlacement="top"
        styles={{
          menuList: styles => ({ ...styles, zIndex: 5, overflowY: 'auto', maxHeight: '140px' }),
          control: provided => ({
            ...provided,
            border: '1px solid #DDDDDD',
            cursor: 'pointer',
            textAlign: 'left'
          }),
          option: provided => ({
            ...provided,
            cursor: 'pointer',
            textAlign: 'left',
            fontWeight: 500,
            ':hover': { ...provided[':hover'], opacity: 0.9 }
          })
        }}
      />
    </div>
  );
};
ReactTablePerPage.propTypes = {
  perPage: PropTypes.number,
  perPageOptions: PropTypes.array,
  setPerPage: PropTypes.func,
  total: PropTypes.number
};

export default ReactTablePerPage;
