import React, { useContext } from 'react';
import { AuthContext } from 'context/Context';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import { Outlet, Link, useLocation } from 'react-router-dom';

const AuthSimpleLayout = () => {
  const { appSettings } = useContext(AuthContext);
  const location = useLocation();

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4 text-center">
          {location.pathname === '/register' ? (
            <Link to="/login">
              <img className="mb-1" src={appSettings.logoISfile.uri} alt="Logo" width={200} />
            </Link>
          ) : (
            <img className="mb-1" src={appSettings.logoISfile.uri} alt="Logo" width={200} />
          )}
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayout;
