import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext from 'context/Context';
import DeleteModal from 'components/common/DeleteModal';

const CardDropdown = ({ btnRevealClass, drop, children, icon = 'ellipsis-h', onDelete, onRename, id }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const [delModal, setdelModal] = useState(false);

  return (
    <>
      <Dropdown className="font-sans-serif btn-reveal-trigger" align={isRTL ? 'start' : 'end'} drop={drop}>
        <Dropdown.Toggle
          variant="link"
          size="sm"
          data-boundary="viewport"
          className={classNames('text-600', {
            [btnRevealClass]: btnRevealClass,
            'btn-reveal': !btnRevealClass
          })}
        >
          <FontAwesomeIcon icon={icon} className="fs--2" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="border py-0">
          {children}
          {!children && (
            <div className="py-2">
              <Dropdown.Item onClick={() => onRename(id)}>Rename</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="text-danger" onClick={() => setdelModal(true)}>
                Delete
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <DeleteModal onShow={delModal} onHide={() => setdelModal(false)} id={id} onDelete={onDelete} />
    </>
  );
};

CardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  id: PropTypes.string
};

export default CardDropdown;
