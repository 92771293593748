export const highlightText = (text, filter) => {
  if (!filter || !text) return text;

  const regex = new RegExp(`(${filter})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === filter.toLowerCase() ? (
      <span key={index} className="highlight">
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const customIncludesString = (text, filter) => {
  if (typeof text !== 'string' || typeof filter !== 'string') return false;

  const normalizedText = text.toLowerCase();
  const normalizedFilter = filter.toLowerCase();

  return normalizedText.includes(normalizedFilter);
};
