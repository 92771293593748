import { useEffect, useState } from 'react';

const DEFAULT_DELAY_MS = 400;
const useDebounce = (value, delay = DEFAULT_DELAY_MS) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);
  return debouncedValue;
};

export default useDebounce;
