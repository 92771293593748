import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';

const DeleteModal = ({ onShow, onHide, id, onDelete, isLoading }) => {
  const handleConfirm = () => {
    onDelete(id);
  };

  return (
    <Modal show={onShow} onHide={isLoading ? null : onHide} contentClassName="border-0" centered>
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <Modal.Title as="h5" className="mt-3">
          Are you sure?
        </Modal.Title>
        {!isLoading && <FalconCloseButton noOutline variant="white" onClick={onHide} />}
      </Modal.Header>
      <Modal.Body>
        <Flex alignItems="center" justifyContent="center" className="mt-3">
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>
              <Button variant="outline-secondary" size="sm" className="px-2 me-3" onClick={onHide}>
                Go Back
              </Button>
              <Button variant="outline-danger" size="sm" className="px-2" onClick={handleConfirm}>
                Yes, Delete
              </Button>
            </>
          )}
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onShow: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default DeleteModal;
