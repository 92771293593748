import React from 'react';
import { Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import CreateNewProjectForm from 'modules/dashboard/CreateNewProjectForm';
import PropTypes from 'prop-types';

const CreateNewProjectModal = ({ isModalVisible, hideModal }) => {
  return (
    <Modal size="md" show={isModalVisible} onHide={hideModal} aria-labelledby="create-new-project-modal">
      <Modal.Header>
        <Modal.Title id="create-new-project-modal">Create project</Modal.Title>
        <FalconCloseButton onClick={hideModal} />
      </Modal.Header>
      <Modal.Body>
        <CreateNewProjectForm hideModal={hideModal} />
      </Modal.Body>
    </Modal>
  );
};

CreateNewProjectModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default CreateNewProjectModal;
