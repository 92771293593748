import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const updateField = async info => {
  const { id, data } = info;
  const res = await axios.patch(`/app/bb_datasheep_projects_fields/${id}`, data);
  return res.data;
};
export const useUpdateFieldMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: updateField,
    onSuccess: data => {
      if (data?.error) {
        return Notiflix.Notify.failure(data.error);
      }
      queryClient.invalidateQueries('PROJECT_FIELDS');
    }
  });

  return { mutate };
};
