import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

export const deleteRules = async rules => {
  const request = rules.map(id => axios.delete(`/app/bb_datasheep_rules/${id}`));
  await Promise.all(request);
};

export const useDeleteRulesMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: deleteRules,
    onSuccess: () => {
      queryClient.invalidateQueries('ALL_RULES');
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { mutate };
};
