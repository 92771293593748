import { useMutation } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const updateInstruction = async info => {
  const { id, ...data } = info;
  const res = await axios.patch(`/app/bb_datasheep_projects/${id}`, data);
  return res.data;
};

export const useExtractionInstructionsMutation = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: updateInstruction,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { mutate, isLoading };
};
