import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavbarVerticalMenu = ({ route }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return (
    <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
      <NavLink
        end={route.exact}
        to={route.to}
        className={({ isActive }) => (isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link')}
      >
        <div className="d-flex align-items-center">
          {route.icon && (
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={route.icon} />
            </span>
          )}
          <span className="nav-link-text ps-1">{route.name}</span>
        </div>
      </NavLink>
    </Nav.Item>
  );
};

NavbarVerticalMenu.propTypes = {
  route: PropTypes.object.isRequired
};

export default NavbarVerticalMenu;
