import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const updateProject = async ({ id, data }) => {
  const res = await axios.patch(`/app/bb_datasheep_projects/${id}`, data);
  return res.data;
};

export const useLockProjectMutation = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: updateProject,
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries(['GET_PROJECT', id]);
      const previousProject = queryClient.getQueryData(['GET_PROJECT', id]);
      queryClient.setQueryData(['GET_PROJECT', id], old => ({ ...old, ...data }));
      return { previousProject };
    },
    onError: (error, { id }, context) => {
      Notiflix.Notify.failure(error.message);
      queryClient.setQueryData(['GET_PROJECT', id], context.previousProject);
    }
  });

  return { mutate, isLoading };
};
