import { useQuery } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

export const getField = async id => {
  const res = await axios.get(`/app/bb_datasheep_projects_fields/${id}`);
  return res.data;
};

export const useGetFieldQuery = id => {
  const { data, isLoading } = useQuery({
    queryKey: ['GET_PROJECT_FIELD', id],
    queryFn: () => getField(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  return { data, isLoading };
};
