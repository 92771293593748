import { useQuery } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { useMemo } from 'react';

const getProjectFields = async id => {
  const res = await axios.get(`/app/bb_datasheep_projects_fields/?projectISbb_datasheep_projectsID=${id}`);
  return res.data;
};

export const useGetProjectFieldsQuery = id => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['PROJECT_FIELDS', id],
    queryFn: () => getProjectFields(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  const sortedData = useMemo(() => {
    const validData = Array.isArray(data) ? data : [];

    return validData.sort((a, b) => {
      const orderNumA = a.orderNUM !== null ? Number(a.orderNUM) : Infinity;
      const orderNumB = b.orderNUM !== null ? Number(b.orderNUM) : Infinity;

      return orderNumA - orderNumB;
    });
  }, [data]);

  return { data: sortedData, isLoading, isError };
};
