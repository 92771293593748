const APP_SETTINGS = {
  name: '',
  title: '',
  version: null,
  modeISLIST_Dev_Prod: null,
  logoISfile: {
    uri: '',
    thumbnail: '',
    filename: '',
    last_modified_date: '',
    type: '',
    size: 1111
  },
  splash_logoISfile: {
    uri: '',
    thumbnail: '',
    filename: '',
    last_modified_date: '',
    type: '',
    size: 1111
  },
  favicon_16ISfile: {
    uri: '',
    thumbnail: '',
    filename: '',
    last_modified_date: '',
    type: '',
    size: 1111
  },
  favicon_32ISfile: {
    uri: '',
    thumbnail: '',
    filename: '',
    last_modified_date: '',
    type: '',
    size: 1111
  },
  favicon_192ISfile: {
    uri: '',
    thumbnail: '',
    filename: '',
    last_modified_date: '',
    type: '',
    size: 1111
  },
  default_route: null,
  auth_typeISLIST_Oauth_Session: '',
  oauth_clientISbb_oauth_clientsID: null,
  auth_username_title: null,
  login_url: null,
  logout_redirect_url: null,
  ip_address_whitelist: null,
  offline_pollingYN: false,
  defaultYN: true,
  dropdowns: {
    user_type: [
      {
        _id: 1,
        name: 'Owner'
      },
      {
        _id: 2,
        name: 'Staff'
      },
      {
        _id: 3,
        name: 'Member'
      }
    ]
  }
};

export default APP_SETTINGS;
