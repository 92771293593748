import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import pagesRoutes from 'routes/pages-routes';

const NoFieldsInfo = ({ handleOpenModal }) => {
  return (
    <>
      <h5 className="mb-5">Fields</h5>
      <div className="d-flex poppins-regular">
        <Button type="button" className="p-0 poppins-regular" variant="link" onClick={handleOpenModal}>
          Add Fields
        </Button>
        &nbsp;
        <span>for data extraction</span>
      </div>
      <p className="mt-2 poppins-regular">
        <Link to={pagesRoutes.rules} className="flex-grow-1">
          Establish rules
        </Link>
        &nbsp;
        <span>to specify which data should be extracted and link them to corresponding fields.</span>
      </p>
    </>
  );
};

NoFieldsInfo.propTypes = {
  handleOpenModal: PropTypes.func.isRequired
};

export default NoFieldsInfo;
