import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { AuthContext } from 'context/Context';
import { useLoginMutation } from 'pages/login-page/mutations/useLoginMutation';
import pagesRoutes from 'routes/pages-routes';
import ModalReg from 'components/common/ModalReg';
import { Loader } from 'components/common/Loader';

const LoginPage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', bodyText: '', isLog: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { username: '', password: '' } });

  const { setAuth, setUser } = useContext(AuthContext);
  const { mutate: handleLogin } = useLoginMutation();

  const onSubmit = data => {
    setIsSubmitting(true);
    handleLogin(data, {
      onSuccess: data => {
        setUser({ first_name: data?.user?.first_name, last_name: data?.user?.last_name });
        setIsLoading(true);
        setTimeout(() => {
          setAuth(true);
          setIsSubmitting(false);
        }, 1000);
      },
      onError: error => {
        setIsSubmitting(false);
        if (error.response && error.response.data && error.response.data.error) {
          setModalInfo({ title: 'Error', bodyText: error.response.data.error, isLog: false });
          setModalShow(true);
        }
      }
    });
  };

  if (isLoading) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Log in</h5>
        <Link to={pagesRoutes.register}>Sign up</Link>
      </Flex>

      <Form onSubmit={handleSubmit(onSubmit)} role="login-form">
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Username"
            type="text"
            isInvalid={!!errors.username}
            {...register('username', { required: 'Username is required' })}
          />
          {errors.username && <Form.Control.Feedback type="invalid">{errors.username.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Password"
            isInvalid={!!errors.password}
            {...register('password', { required: 'Password is required' })}
            type="password"
          />
          {errors.password && <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>}
        </Form.Group>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Link className="fs--1 mb-0" to={pagesRoutes.forgotPassword}>
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Form.Group>
          <Button type="submit" color="primary" className="mt-3 w-100" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Logging in...
              </>
            ) : (
              'Log in'
            )}
          </Button>
        </Form.Group>

        <p className="mt-2 mb-0 fs--1 text-600">
          <a href="https://www.blueboxonline.com/termsuk" target="_blank" rel="noreferrer noopener">
            Terms
          </a>{' '}
          <span className="d-none d-sm-inline-block"> | </span>{' '}
          <a href="https://www.blueboxonline.com/privacy" target="_blank" rel="noreferrer noopener">
            Privacy
          </a>{' '}
          <span className="d-none d-sm-inline-block"> | &copy; &nbsp; </span>
          <a href="https://www.blueboxonline.com/" target="_blank" rel="noreferrer noopener">
            BlueBox
          </a>
          <br className="d-sm-none" /> {new Date().getFullYear()}
          <br/>
          <br/>
          {'v2.21'}
        </p>
      </Form>

      <ModalReg
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={modalInfo.title}
        bodyText={modalInfo.bodyText}
        isLog={modalInfo.isLog}
        loginRoute={pagesRoutes.login}
      />
    </>
  );
};

export default LoginPage;
